<template>
  <v-card >
      <v-card-title :class="`${color}--text`" v-html="$options.filters.t(title, values ? values.name : '')">
      </v-card-title>
      <v-card-text >
          <br>
         <VFormBuilder :fields="fields"
          :defaultsFieldsProps="{
                            rounded: true,
                            dense: true,
                            outlined: true
                        }" 
            ref="form"
            @submit="confirm"
          />
      </v-card-text>
      <v-card-actions>
          <v-btn :color="color" rounded outlined @click="submit" :loading="loading">
              <v-icon class="mx-1" small>{{ confirmIcon}}</v-icon>
              {{ confirmText | t }}
          </v-btn>
          <v-btn  outlined rounded @click="cancel" :disabled="loading">
              <v-icon class="mx-1" small >{{ cancelIcon }}</v-icon>
              {{ cancelText | t }}
          </v-btn>
      </v-card-actions>
  </v-card>
</template>

<script>
import VFormBuilder from './../VFormBuilder.vue'
export default {
    props: {
        name: String,
        title: String,
        text: String,
        size: Number,
        color: {
            type: String,
            default: 'success'
        },
        confirmText: {
            type: String,
            default: 'confirm'
        },
        confirmIcon: {
            type: String,
            default: 'mdi-check'
        },
        cancelText: {
            type: String,
            default: 'cancel'
        },
        cancelIcon: {
            type: String,
            default: 'mdi-close'
        },
        loading: Boolean,
        values: Object
    },
    components: {
        VFormBuilder
    },
    computed: {
        fields () {
            const vm = this
            const { $t: __, values } = vm
           
            return  [
                {
                    name: 'name',
                    input: () => import('vuetify/lib/components/VTextField'),
                   
                    attrs: fvm => ({
                        get value () {
                            if (values && values.name)
                                return values.name
                            return __(fvm.values.type !== 'spacer' ? 'number_of_table' : 'number_of_spacer', vm.size + 1)
                        },
                        label: __('table_name'),
                        startIcon: 'mdi-label',
                        required: true,
                        rules: vm.getFieldRule('required')

                    }),
                    col: {
                        cols: 12,
                        sm: 8
                     }
                },
                {
                    name: 'number',
                    input: () => import('vuetify/lib/components/VTextField'),
                    if: fvm => fvm.values.type !== 'spacer',
                    attrs: {
                        get value() {
                            if (values && values.number)
                                return values.number
                            return vm.size + 1
                        },
                        label: __('table_number'),
                        startIcon: 'mdi-counter',
                        required: true,
                        rules: vm.getFieldRule('required'),
                        type: 'number'

                    },
                    col: {
                        cols: 12,
                        sm: 4
                     }
                },
                {
                    name: 'seats',
                    input: () => import('vuetify/lib/components/VTextField'),
                    if: fvm => fvm.values.type !== 'spacer',
                    attrs: {
                        value: values && values.seats ? values.seats : 2,
                        label: __('seats_number'),
                        startIcon: 'mdi-seat',
                        required: true,
                        rules: vm.getFieldRule('required'),
                        type: 'number'

                    },
                    col: {
                        cols: 12,
                        sm: 4
                     }
                },
                {
                    name: 'type',
                    input: () => import('./../VTableType.vue'),
                    attrs: {
                        value: values && values.type ? values.type : 'square',
                        label: __('type'),
                        startIcon: 'mdi-table-chair',
                        required: true,
                        rules: vm.getFieldRule('required'),

                    },
                    on: fvm => ({
                        input: () => {
                            if (vm.values) return
                            fvm.values.name = __(fvm.values.type !== 'spacer' ? 'number_of_table' : 'number_of_spacer', vm.size + 1)
                        }
                    }),
                    col: {
                        cols: 12,
                     }
                },
            ]
        }
    },
    methods: {
        submit() {
            const { form } = this.$refs
            form.submit()
        },
        confirm (data) {
            this.$emit('confirm', data)
        },
        cancel () {
            this.$emit('cancel')
        }
    }
}
</script>


