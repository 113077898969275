<template>
    <v-form ref="form" @submit.prevent="submit" v-bind="$attrs">
       
        <v-row class="ma-0" v-if="isDocForm">
            <v-col cols="12" sm="8">
                <v-fade-transition group>
                    <v-card v-for="(panel, ind) in filterPanels(fields.content)" :key="ind" flat style="border: 1px solid rgba(0, 0, 0, 0.12)" class="mb-6">
                        <v-card-title>
                            <v-icon class="mx-1" v-if="panel.icon" >{{panel.icon}}</v-icon>
                            {{ panel.text | t }}
                        </v-card-title>
                        <v-card-text>
                            <v-row class="ma-0" >
                                
                                    <v-col class="pa-0" v-for="({name}, n) in filterFields(panel.fields)" :key="n" v-bind="cols[name]">
                                        <v-fade-transition >
                                        <component
                                            :is="inputs[name]"
                                            :name="name"
                                            v-model="values[name]"
                                            v-bind="getAttrs(name)"
                                            v-on="listeners[name]"
                                        ></component>
                                        </v-fade-transition>  
                                    </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-fade-transition>   

            </v-col>
            <v-col cols="12" sm="4">
                <v-fade-transition group>

                    <v-card v-for="(panel, ind) in filterPanels(fields.sidebar)" :key="ind" flat style="border: 1px solid rgba(0, 0, 0, 0.12)" class="mb-6">
                        <v-card-title>
                            <v-icon class="mx-1" v-if="panel.icon" >{{panel.icon}}</v-icon>
                            {{ panel.text | t }}
                        </v-card-title>
                        <v-card-text>
                            <v-row class="ma-0" >
                                <v-col v-for="({name}, n) in filterFields(panel.fields)" :key="n" v-bind="cols[name]" class="pa-0">
                                    <v-fade-transition >
                                        <component
                                            :is="inputs[name]"
                                            :name="name"
                                            v-model="values[name]"
                                            v-bind="getAttrs(name)"
                                            v-on="listeners[name]"
                                        ></component>
                                    </v-fade-transition>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-fade-transition>
            </v-col>
        </v-row>
        <v-row class="ma-0" v-else>
            <v-col v-for="(name, n) in getFormFields" :key="n" v-bind="cols[name]">
                <component
                    :is="inputs[name]"
                    :name="name"
                    v-model="values[name]"
                    
                    v-bind="getAttrs(name)"
                    v-on="listeners[name]"
                ></component>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>

const setIcons = (attrs, rtl) => {

    if ( attrs.startIcon ) {
        const { startIcon } = attrs
        delete attrs.startIcon
        if (rtl)
            attrs['prepend-inner-icon'] = startIcon
        else
            attrs['append-icon'] = startIcon
    }  
    if ( attrs.endIcon ) {
        const { endIcon } = attrs
        delete attrs.endIcon
        if (rtl)
           attrs['append-icon'] = endIcon
        else
            attrs['prepend-inner-icon'] = endIcon
    }
}
const setIconsClick = (on, rtl) => {

    if ( on.startIcon ) {
        const { startIcon } = on
        delete on.startIcon
        if (rtl)
            on['click:prepend-inner'] = startIcon
        else
            on['click:append'] = startIcon
    }  
    if ( on.endIcon ) {
        const { endIcon } = on
        delete on.endIcon
        if (rtl)
            on['click:append'] = endIcon
        else
            on['click:prepend-inner'] = endIcon
    }
}

export default {
    props: {
        fields: [Array, Object],
        document: Object,
        defaultsFieldsProps: Object
    },
    data: () => ({
        formContentFields: [],
        formSidebarFields: [],
        formFields: [],
        inputs: {},
        values: {},
        attrs: {},
        listeners: {},
        cols: {},
        condetions: {}
    }),
    watch: {
        fields: {
            handler: 'init',
            immediate: true
        }
    },
    computed: {
        isDocForm () {
            return this.fields instanceof Object && !Array.isArray(this.fields)
        },
        getFormFields () {
            return  this.formFields.filter(name => this.getCondetion(name)) 
        },
        allFields () {
            if (this.isDocForm) {
                const { sidebar, content } = this.fields  
                const all = [];
                [sidebar, content].forEach(panels => panels.forEach(({fields}) =>  {
                   all.push(...fields.map(f => f.name))
                     
                }))
                return all
            }


            return this.fields.map(e => e.name)
        }
    },
    methods: {
        filterFields (fields) {
            return fields.filter(({name}) => this.getCondetion(name))
        },
        filterPanels (panels) {
            return panels.filter(({if: condetion }) => {
                if (condetion === undefined) return true
                return typeof condetion == 'function' ? condetion(this) : condetion
            })
        },
        setFields (fields) {
            fields.forEach(({ name, input, attrs, on, col, if: condetion, value  }, n)=> {
                if (!name)
                    throw  'missing "name" attribute for field in index {'+n+'}';
             
               
                
                attrs = attrs || {}
                attrs = typeof attrs == 'function' ? attrs(this) : attrs
                on  = (typeof on == 'function' ? on(this) : on) || {}
                

                if (this.defaultsFieldsProps)
                    attrs = { ...attrs, ...this.defaultsFieldsProps}

                setIcons(attrs, this.$vuetify.rtl)
                setIconsClick(on, this.$vuetify.rtl)
               
                this.$set(this.condetions, name, condetion === undefined || condetion)
                this.$set(this.cols, name, col || { cols: 12 })
                this.$set(this.inputs, name, input)
                this.$set(this.values, name,  value || (typeof attrs.value == 'function' ? attrs.value(this) : attrs.value))
              

                delete  attrs.value
                this.$set(this.attrs, name,  attrs)
                this.$set(this.listeners, name,  on )
                

            })
        },
        getAttrs(name) {
            const dAttrs = this.attrs[name]
            const attrs = {}
            Object.keys(dAttrs).forEach(attr => {
                
                attrs[attr] = typeof dAttrs[attr] == 'function' ? dAttrs[attr](this) : dAttrs[attr]
            })
                
            return attrs
        },
        getCondetion(name) {
            const field = this.fields.find(e => e.name === name)
            if (typeof field.input !== 'function') return false // avoid rendering hidden component
            
            const dCondetion = this.condetions[name]
            return typeof dCondetion == 'function' ? dCondetion(this) : dCondetion
        },
     
        resetFormFields () {
            this.formFields= []
            this.values= {}
            this.props= {}
            this.inputs= {}
            this.cols= {}
            this.condetions= {}
        },
        init () {
             this.resetFormFields()

            if(this.isDocForm) {
                const { sidebar, content } = this.fields  
                this.formFields = [];
                [sidebar, content].forEach(panels => panels.forEach(({fields}) =>  {
                    this.setFields(fields)
                    this.formFields.push(...fields.map(f => f.name))
                }))
            } else {
                this.setFields(this.fields)
                this.formFields = this.fields.map(f => f.name)
            }
                
        },
        resetValidation () {
            const { form } = this.$refs
            form.resetValidation()
        },
        resetForm () {
            const { form } = this.$refs
            form.reset()

        },
        submit () {
            const { form } = this.$refs
            if (!form.validate()) {
                this.$emit('error', 'form.invalid')
                return
            }
            const { allFields, values } = this
            const data = {}
            allFields.forEach(name => {
                if (values[name] !== undefined)
                    data[name] = values[name]
            })
            this.$emit('submit', data)
        }
    } 
}
</script>

